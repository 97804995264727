<template>
  <div class="dashboard">
    <div class="container">
      <div class="search">
        <div class="row">
          <div class="col-md-12 mb-3">
            <router-link
              :to="{ name: 'app.create' }"
              class="btn btn-primary btn-xs float-right"
              ><i class="lni-plus mr-2"></i> New App</router-link
            >
            <h4>My Apps</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-3">
            <div class="filter">
              <input
                type="text"
                placeholder="Filter By Name"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="sort">
              <select class="form-control">
                <option value="created_at">Sort By Created Date</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <ul class="apps-list">
        <li>
          <a href="#" class="title">
            <span class="name">Name</span>
            <span class="status">Environment</span>
            <span class="balance">Balance</span>
            <span class="date">Date Created</span>
          </a>
        </li>
        <li v-for="(app, i) in apps" :key="`app-${i}`">
          <router-link :to="{ name: 'app.overview', params: { id: app.key } }">
            <span class="name"
              ><img :src="app.image" :alt="app.name" />{{ app.name }}</span
            >
            <span class="status"
              ><span
                :class="
                  `badge badge-${
                    app.environment == 'live' ? 'success' : 'danger'
                  }`
                "
                ><i class="lni-target mr-2"></i> {{ app.environment }}</span
              ></span
            >
            <span class="balance">KES {{ app.balance }}</span>
            <span class="date">{{ app.created_at }}</span>
          </router-link>
        </li>
      </ul>
      <div class="alert alert-info text-center" v-if="apps.length == 0">
        <p>You have no current apps.</p>
        <router-link :to="{ name: 'app.create' }" class="btn btn-primary"><i class="lni-plus mr-2"></i>Create one to begin</router-link>
      </div>
      <div class="apps-pagination">
        <paginate
          :page-count="1"
          :click-handler="goToNext"
          :prev-text="'Prev'"
          :next-text="'Next'"
          :container-class="'pagination'"
          :page-class="'page-item'"
          page-link-class="page-link"
          prev-class="page-item"
          prev-link-class="page-link"
          next-class="page-item"
          next-link-class="page-link"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      apps: [],
    };
  },

  methods: {
    fetch() {
      this.$loader.start("dashboard-list");

      this.$axios.get("/api/v1/apps").then((response) => {
        this.apps = response.data;
        this.$loader.stop();
      });
    },

    goToNext() {},
  },
};
</script>
