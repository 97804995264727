<template>
  <div>
    <app-header />

    <router-view />
  </div>
</template>

<script>
import AppHeader from '@/components/dashboard/AppHeader'

export default {
  components: {
    AppHeader
  },

  mounted() {
    if(! this.user.phone_number_verified) {
      // this.$router.push({ name: 'dashboard.verify-phone' })
    }
  },

  computed: {
    user() {
      return this.$store.getters.getAuthUser
    }
  }
}
</script>