<template>
  <header class="header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-2">
          <h4 class="logo"><a href="#" class="toggler"></a> <router-link :to="{ name: 'dashboard' }">TRADESK</router-link></h4>
        </div>
        <div class="col-md-4">
          <div class="apps" v-if="app_is_selected">
            <a href="#" class="current"><i class="lni-layout mr-2"></i> {{ current_app.name }} <i class="lni-chevron-down ml-4"></i></a>
            <ul class="all-apps shadow">
              <li v-for="(app, i) in all_apps" :key="`all-apps-${i}`">
                <router-link :to="{ name: 'app.overview', params: { id: app.key }}">{{ app.name }}</router-link>
              </li>
              <li class="all">
                <router-link :to="{ name: 'dashboard' }">View All</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3">
          <form>
            <input type="text" placeholder="Search" class="form-control">
          </form>
        </div>
        <div class="col-md-3">
          <ul class="menu">
            <li>
              <router-link :to="{ name: 'app.create' }"><i class="lni-plus"></i></router-link>
            </li>
            <li>
              <router-link :to="{ name: 'notifications' }" title="Notifications"><i class="lni-alarm"></i> <span :class="`notifications-count ${unread_notifications > 0 ? '' : 'hidden'}`">{{ unread_notifications > 0 ? unread_notifications : '' }}</span></router-link>
            </li>
            <li>
              <a href="https://docs.tradesk.co.ke" target="_blank" title="Help"><i class="lni lni-question-circle"></i></a>
            </li>
            <li>
              <a href="#" @click.prevent=""><i class="lni lni-user mr-2"></i><span class="username">{{ user_first_name }}</span></a>
              <ul class="sub-menu">
                <li>
                  <router-link to="">My Account</router-link>
                </li>
                <li>
                  <a href="#" @click.prevent="logout">Logout</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      unread_notifications: 0
    }
  },

  methods: {
    fetch() {
      this.$axios.get('/api/v1/notifications/count').then(response => {
        this.unread_notifications = response.data

        setTimeout(() => {
          this.fetch()
        }, 900000)
      })
    },

    logout() {
      this.$loader.start()
      
      this.$axios.post('/api/v1/logout').then(() => {
        this.$auth.removeToken()
        window.location.href = this.$router.resolve({ name: 'login' }).href
      })
    }
  },

  computed: {
    app_is_selected() {
      return this.$store.getters.getApp.name != null && this.$route.params.id != null
    },

    current_app() {
      return this.$store.getters.getApp
    },

    all_apps() {
      return this.$store.getters.getApps
    },

    user() {
      return this.$store.getters.getAuthUser
    },

    user_first_name() {
      return this.user.name.split(' ')[0]
    }
  }
}
</script>